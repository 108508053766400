import React from 'react';

import CompanyLogo from 'assets/img/logo-mma-yelum.svg';

import { useSearchLogo } from 'hooks/useSearchLogo';

import * as S from './styles';
import appConfig from '../../../assets/settings/appConfig.PRD.json'

export default function FooterEmbbed() {
  const [logo] = useSearchLogo();

  return (
    <footer>
      <S.ContainerLogo>
        <S.LogoImg src={logo} alt="Imagem de um logo." />
      </S.ContainerLogo>
      <S.FooterContainer>
        <S.FooterContent>
          <S.Logo>
            <img src={CompanyLogo} alt=""/>
          </S.Logo>

          <S.FooterDescription>
            { appConfig.COPY_RIGHTS } - Rua Dr. Geraldo Campos Moreira, 110 - Brooklin
            Novo - São Paulo/SP - CEP:04.571-020 - CNPJ 61.550.141/0001-72 -
            Cód. SUSEP: 518-5. Ouvidoria: acesse o site
            www.yelumseguros.com.br, onde é possível postar seu recurso. Se
            preferir, o recurso também poderá ser feito pelo e-mail
            ouvidoria@yelum.com.br ou pelo telefone 0800-740-3994.
            <p>©2024 { appConfig.COMPANY_FULL_NAME }. Todos os direitos reservados.</p>
          </S.FooterDescription>
        </S.FooterContent>
      </S.FooterContainer>
    </footer>
  );
}
